import { useContext, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import toast from "react-hot-toast";
import { PopupLayout } from "@/components/popupLayout";
import { LiffFooter } from "@/components";
import { LiffContext, api, AppError } from "@/utils";
import { useGetPointInfo } from "@/hooks";
import { TierStyle } from "@/utils/tierStyles";
import CloseButton from "@/assets/img/liff/close-button.png";

let isGettingCrmMember = false;

const LiffLayout = () => {
	const { liffState, liffActions } = useContext(LiffContext);
	const { getPointInfo } = useGetPointInfo();
	const [loading, setLoading] = useState(true);
	const [isUpgradePopupOpen, setIsUpgradePopupOpen] = useState(false);
	const [isMemberBenefitsPopup, setIsMemberBenefitsPopup] = useState(false);
	const [tierStyle, setTierStyle] = useState({});
	const isShowUpgradePopup = useRef(false);

	// 獲取會員基本資料
	const getCrmMember = async () => {
		// 禁止重複觸發
		if (isGettingCrmMember) return;
		isGettingCrmMember = true;

		try {
			// 發生錯誤api retry
			const res = await api(10).get("/get_crm_member");
			isShowUpgradePopup.current = res.data.upgrade_notified;
			liffActions.setMemberData(res.data);
			const tierCode = res.data.tierCode;
			getTierStyle(tierCode);

			// 會員權益
			liffActions.setMemberBenefitPopup({
				open: (tierCode) => {
					getTierStyle(tierCode);
					setIsMemberBenefitsPopup(true);
				},
				close: () => setIsMemberBenefitsPopup(false),
			});
		} catch (error) {
			console.error("Get Crm Member Failed", error);
			if (error.response) {
				const errorCode = error.response.data.error.code;
				switch (errorCode) {
					case AppError.CRM_FAILED:
						toast.error("發生錯誤，請再試一次。");

						break;

					default:
						toast.error("發生錯誤，請再試一次。");
				}
			}
		} finally {
			setLoading(false);
			isGettingCrmMember = false;
		}
	};

	const getTierStyle = (tierCode) => {
		if (tierCode) {
			const tierStyle = new TierStyle();
			const batchTierStyle = tierStyle.getTierStyleByCode(tierCode);
			setTierStyle(batchTierStyle);
			return batchTierStyle;
		}
		return null;
	};

	const handleUpgradePopupClose = () => {
		setTimeout(() => {
			setIsMemberBenefitsPopup(true);
		}, 500);

		try {
			api().post("/update_upgrade_notified");
		} catch (error) {
			console.error("Update Upgrade Notified Failed", error);
		} finally {
			setIsUpgradePopupOpen(false);
			isShowUpgradePopup.current = false;
		}
	};

	useEffect(() => {
		if (!liffState.showFullPageLoader && isShowUpgradePopup.current) {
			setTimeout(() => {
				setIsUpgradePopupOpen(true);
			}, 500);
		}
	}, [liffState.showFullPageLoader]);

	const checkQueryStringForUpgradePopup = () => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const levelUp = urlParams.get("notify-level-up");
		if (levelUp) {
			setTimeout(() => {
				setIsUpgradePopupOpen(true);
			}, 500);
		}
	};

	useEffect(() => {
		getCrmMember();
		getPointInfo();
		checkQueryStringForUpgradePopup();
	}, []);

	if (loading) {
		return;
	}

	return (
		<>
			{/* 會員權益 */}
			<PopupLayout
				isOpen={isMemberBenefitsPopup && tierStyle}
				handleOutsideClick={false}
				onClose={() => setIsMemberBenefitsPopup(false)}
				animation="slide"
				zIndex={19}
			>
				{tierStyle && (
					<div className="member-benefits-popup">
						<div className="top-bar">
							<img className="tier-icon" src={tierStyle.vipIcon}></img>
							<label>
								<span style={{ color: tierStyle.color }}>
									{`${tierStyle.tierName} `}
								</span>
								會員權益
							</label>
							<img
								className="close-btn"
								src={CloseButton}
								onClick={() => setIsMemberBenefitsPopup(false)}
							></img>
						</div>
						<div className="content-container">
							<div className="content">
								<img
									src={tierStyle.memberBenefit}
									// 裁切圖片上方bar，若圖片樣式改變，則需要調整或移除
									style={{ objectPosition: "0px -14em", objectFit: "cover" }}
									alt=""
								></img>
							</div>
							<div className="shadow"></div>
						</div>
					</div>
				)}
			</PopupLayout>

			{/* 升級彈窗 */}
			<PopupLayout
				isOpen={isUpgradePopupOpen}
				handleOutsideClick={false}
				onClose={() => setIsUpgradePopupOpen(false)}
			>
				<div className="upgrade-popup">
					<img src={tierStyle.upgradePopup} alt=""></img>
					<button onClick={() => handleUpgradePopupClose()}>
						<span>點我了解</span>
					</button>
				</div>
			</PopupLayout>
			<LiffFooter></LiffFooter>
			<Outlet></Outlet>
		</>
	);
};

export default LiffLayout;
