import { useState } from "react";
import { PopupLayout } from "@/components/popupLayout";
import { CouponState } from "./CouponPage";
import { useNavigate } from "react-router-dom";
import CloseButton from "@/assets/img/liff/close-button.png";
import ExpiredOverlay from "@/assets/img/liff/coupons/expired-overlay.png";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import "./index.sass";

const Coupon = ({ coupon, isExpired }) => {
	const navigate = useNavigate();
	const [isInstructionsPopupOpen, setIsInstructionsPopupOpen] = useState(false);

	const formatDate = (dateString) => {
		return dayjs(dateString).format("YYYY/MM/DD");
	};

	const handleInstructionButtonClick = () => {
		setIsInstructionsPopupOpen(true);
	};

	const handleSecondInstructionButtonClick = () => {
		if (coupon.giftCard && coupon.state === CouponState.USED) {
			toast.error(
				"您已完成兌換地址填寫\n請靜候出貨，有任何問題\n請聯繫品牌客服",
			);
			return;
		} else if (
			coupon.invalidTime &&
			dayjs().isAfter(dayjs(coupon.invalidTime))
		) {
			toast.error("優惠券已過期");
			return;
		}

		navigate(
			`/coupon/gift-delivery?couponCode=${coupon.couponCode}&giftCard=${coupon.giftCard}&hash=${coupon.hash}`,
		);
	};

	return (
		<div className="cCoupon">
			{/* 使用說明 */}
			<PopupLayout
				isOpen={isInstructionsPopupOpen}
				handleOutsideClick={false}
				onClose={() => setIsInstructionsPopupOpen(false)}
				animation="slide"
				zIndex={19}
			>
				<div className="coupon-instruction-popup">
					<div className="top-bar">
						<label>{`使用說明`}</label>
						<img
							className="close-btn"
							src={CloseButton}
							onClick={() => setIsInstructionsPopupOpen(false)}
						></img>
					</div>
					<div className="content-container">
						<div className="content">
							<div className="section">
								<label>優惠券名稱：</label>
								<label>{coupon.couponName}</label>
							</div>
							<div className="section">
								<label>優惠券副標：{coupon.couponTitle}</label>
							</div>
							<div className="section">
								<label>
									使用期間：
									{`${formatDate(coupon.validTime)} - ${formatDate(coupon.invalidTime)}`}
								</label>
							</div>
							<div className="section">
								<label>使用說明：</label>
								<label>{coupon.useRuleDesc}</label>
							</div>
						</div>
						<div className="shadow"></div>
					</div>
				</div>
			</PopupLayout>
			<img className="coupon-background" src={coupon.image}></img>
			<div className="coupon-content">
				<div className="description" style={{ color: coupon.textColor }}>
					<label className="name">{coupon.couponName}</label>
					<label className="title">
						{coupon.giftCard || coupon.couponTitle}
					</label>
					<label className="date">{`${formatDate(coupon.validTime)} - ${formatDate(coupon.invalidTime)}`}</label>
				</div>
				<div className="instruction-buttons">
					<div
						className="instruction-button"
						onClick={handleInstructionButtonClick}
					/>
					{coupon.giftCard && coupon.useChannel === 100000007 && (
						<div
							className="instruction-button"
							onClick={handleSecondInstructionButtonClick}
						/>
					)}
				</div>
			</div>
			{isExpired && (
				<div
					className="expired-overlay"
					style={{ backgroundImage: `url(${ExpiredOverlay})` }}
				>
					已過期
				</div>
			)}
		</div>
	);
};

export { Coupon };
